<template>
    <section>
        <modal ref="modalPago" titulo="Pagar en Linea" no-aceptar no-cancelar>
            <div class="row mx-0 justify-center">
                <div class="col-6">
                    <p class="ml-2 text-general f-500">Valor a pagar</p>
                    <div class="d-middle">
                        <money v-model="value" class="input-money" v-bind="money" />
                        <!-- <span class="border p-1 br-r-10 bg-gris f-600" style="margin-left: -8px; width: 50px; height:32px;">{{ pedido.sigla_moneda }}</span> -->
                    </div>
                    <p v-show="errorValue" class="f-11 text-danger">El valor maximo a pagar es de {{ maxValue }}</p>
                </div>
                <div class="row mx-0 justify-center mt-3">
                    <div class="border text-general f-14 px-3  br-8 mx-2 cr-pointer" @click="crearBotonPayu">
                        <img src="/img/icons/boton_payu.png" alt="" width="250" height="80" />
                    </div>
                    <div id="idPayuButtonContainer" />
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import {Money} from 'v-money'
import Pedidos from '~/services/pedidos/pedidos-tendero'
export default {
    components:{
        Money,
    },
    data(){
        return {
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            value:0,
            maxValue:0,
            errorValue:false
        }
    },
    computed:{
        id_admin_pedido(){
            return this.$route.query.id_admin_pedido
        }
    },
    methods: {
        toggle(totalValue){
            this.value = totalValue
            this.maxValue = _.cloneDeep(totalValue)
            this.money.precision = this.$tienda.decimales
            this.$refs.modalPago.toggle();
        },
        async crearBotonPayu(){
            try {
                if(!this.id_admin_pedido) return;

                if(this.value > this.maxValue){
                    this.errorValue = true
                    return
                }else{
                    this.errorValue = false
                }

                const params = {
                    id_admin_pedido: this.id_admin_pedido,
                    amount:this.value,
                    total_amount: this.maxValue
                }

                const {data} = await Pedidos.get_datos_payu(params)

                return;

                const infoPago = data.infoPago

                var html_button="<form id='formPago' method='post' action='https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu'>\
              <input name='merchantId'    type='hidden'  value='"+infoPago.merchantId+"'   >\
              <input name='accountId'     type='hidden'  value='"+infoPago.accountId+"'   >\
              <input name='extra1'     type='hidden'  value='"+infoPago.extra1+"'   >\
              <input name='extra2'     type='hidden'  value='"+infoPago.dataBase+"'   >\
              <input name='description'   type='hidden'  value='"+infoPago.description+"'   >\
              <input name='referenceCode' type='hidden'  value='"+infoPago.referenceCode+"'   >\
              <input name='amount'        type='hidden'  value='"+this.value+"'   >\
              <input name='tax'           type='hidden'  value='"+infoPago.tax+"'   >\
              <input name='taxReturnBase' type='hidden'  value='"+infoPago.taxReturnBase+"'   >\
              <input name='currency'      type='hidden'  value='"+infoPago.currency+"'   >\
              <input name='signature'     type='hidden'  value='"+infoPago.signature+"'   >\
              <input name='test'          type='hidden'  value='"+infoPago.test+"'   >\
              <input name='buyerEmail'    type='hidden'  value='"+infoPago.buyerEmail+"'   >\
              <input name='buyerFullName'    type='hidden'  value='"+infoPago.buyerFullName+"'   >\
              <input name='responseUrl'    type='hidden'  value='"+infoPago.responseUrl+"'   >\
              <input name='confirmationUrl'    type='hidden'  value='"+infoPago.confirmationUrl+"'   >\
            </form>";

                var elem = document.getElementById('idPayuButtonContainer');
                elem.innerHTML = html_button;
                document.getElementById('formPago').submit();
            } catch (e){
                this.error_catch(e)
            }



        },

    }
}
</script>
